import Link from 'next/link';
import { useRouter } from 'next/router';

type LinkerProps = React.PropsWithChildren<{
  url: string;
  title?: string;
  ariaLabel?: string;
  classes?: string;
  target?: string;
}>;
export function Linker({ url, classes, title, ariaLabel, children, target }: LinkerProps) {
  const router = useRouter();
  // console.log(router.asPath)
  // console.log(router.pathname, url)
  if (!url) {
    return <>{children}</>
  } else if (url.includes('https') || url.includes('http') || target === '_blank') {
    // eslint-disable-next-line react/jsx-no-target-blank
    return <a href={url} target='_blank' rel={(url.includes('https') || url.includes('http')) ? 'noopener noreferrer' : ''} className={classes ? classes : ''} title={title} aria-label={ariaLabel}>{children}</a>
  } else {
    return <Link href={url}><a className={`${classes ? classes : ''} ${router.asPath === url ? "active" : ""}`} title={title} aria-label={ariaLabel}>{children}</a></Link>
  }
}
